import {DataTable, DataTableColumn, DataTableSortStatus, useDataTableColumns} from "mantine-datatable";
import {useContext, useEffect, useState} from "react";
import {ActionIcon, Button, Group, Pagination, Stack, Text, TextInput, Tooltip} from "@mantine/core";
import {IconEdit, IconEye} from "@tabler/icons-react";
import ComputerPageContext from "../../../../../contexts/pages/computer/ComputerPageContext";
import Computer from "../../../../../api/computer/Computer";
import {useNavigate} from "react-router-dom";
import DateTime from "../../../../components/_utils/date-time/DateTime";

type ComputerMantineTableProps = {}

const initSortStatus: DataTableSortStatus = {
    columnAccessor: 'id',
    direction: 'desc',
}

/**
 * Simple implementation of computers table in Mantine DataTable.
 * It's so simple, there's no action buttons etc.
 * Renders 'Brak danych', if given page is undefined.
 * @param _props
 * @constructor
 */
function ComputerMantineTable(_props: ComputerMantineTableProps) {
    const navigate = useNavigate();
    const pageContext = useContext(ComputerPageContext);
    const [page, _2] = pageContext.page;
    const [_1, setComputerToEdit] = pageContext.computerToEdit;
    const [query, setQuery] = pageContext.query;

    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>(initSortStatus);

    const columns: DataTableColumn[] = [
        {accessor: 'id', title: '#', sortable: true},

        {
            accessor: 'title',
            title: 'Nazwa komputera',
            sortable: true,
            filter: ({close}) => (
                <Stack>
                    <TextInput
                        placeholder="Nazwa komputera"
                        label=""
                        onChange={ev => setQuery({...query, q: ev.target.value})}
                    />

                    <Button
                        color="red"
                        size="xs"
                        children="Wyczyść"
                        onClick={() => {
                            setQuery({...query, q: undefined});
                            close();
                        }}
                    />
                </Stack>
            ),
            filtering: query.q !== undefined,
            render: (r: any) => <Text c={r.online ? 'green' : 'red'} children={r.title}/>
        },
        {accessor: 'description', title: 'Opis'},
        {accessor: 'userName', title: 'Nazwa użytkownika', sortable: true, sortKey: 'user_name'},
        {accessor: 'domainName', title: 'Domena', sortable: true, sortKey: 'domain_name'},
        {
            accessor: 'config.maxIdleSeconds',
            title: 'Czas bezczynności',
            sortable: true,
            sortKey: 'config__max_idle_seconds',
            render: (r) => (
                <Tooltip
                    color="dark"
                    openDelay={3000}
                    label="Liczba minut po których nieużywane programy są wyłączane. '-' oznacza wartość domyślną."
                    children={<div>{r.config ? (r.config as any).maxIdleSeconds / 60 + ' minut' : '-'}</div>}
                />
            )
        },
        // {
        //     accessor: 'lastConnected',
        //     title: 'Ostatnie połączenie',
        //     sortable: true,
        //     sortKey: 'last_connected',
        //     render: (r: any) => r.lastConnected ? <DateTime dateTime={r.lastConnected}/> : "-"
        // },
        // {
        //     accessor: 'lastDisconnected',
        //     title: 'Ostatnio widziano',
        //     sortable: true,
        //     sortKey: 'last_disconnected',
        //     render: (r: any) => r.lastDisconnected ? <DateTime dateTime={r.lastDisconnected}/> : '-'
        // },
        {
            accessor: 'config.programs',
            title: 'Programy',
            sortable: false,
            noWrap: true,
            render: (r) => (
                <Group justify="left" wrap="nowrap">
                    {(r as any)?.config?.programs?.map(({title}: any) => title).join(", ")}
                </Group>
            )
        },
        {
            accessor: 'actions',
            title: '',
            sortable: false,
            noWrap: true,
            render: (r) => (
                <Group justify="right" gap="md" wrap="nowrap">
                    <ActionIcon
                        size="sm"
                        variant="subtle"
                        color="green"
                        onClick={() => navigate(`/computer/${r.id}`)}
                        children={<IconEye size={16}/>}
                    />

                    <ActionIcon
                        size="sm"
                        variant="subtle"
                        color="blue"
                        onClick={() => {
                            setComputerToEdit(r as any as Computer)
                        }}
                        children={<IconEdit size={16}/>}
                    />
                </Group>
            )
        }
    ];

    const onSortStatusChanged = () => {
        const newQuery = {
            ...query,
            sortBy: sortStatus.sortKey ?? sortStatus.columnAccessor,
            sortDir: sortStatus.direction
        };
        setQuery(newQuery);
    }

    useEffect(onSortStatusChanged, [sortStatus]);

    return (
        <Stack>
            <DataTable
                styles={{header: {background: 'var(--mantine-color-dark-7)'}}}
                backgroundColor="transparent"
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                highlightOnHover
                withColumnBorders
                columns={columns}
                records={page?.results ?? []}
                minHeight="50vh"
                noRecordsText="Ale pusto..."
                fetching={pageContext.isFetching}
            />

            <Pagination
                total={page?.totalPages ?? 1}
                onChange={(e) => setQuery({...query, page: e})}
            />
        </Stack>
    );
}

export default ComputerMantineTable;

// TODO: ComputerMantineTable.tsx
//  - Improve table [if we want to use it]