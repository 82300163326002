import Page from "../../../api/Page";
import Kill from "../../../api/kill/Kill";
import {DataTable, DataTableColumn, DataTableSortStatus} from "mantine-datatable";
import {Button, Group, Pagination, Stack, TextInput} from "@mantine/core";
import ListQuery from "../../../api/kill/ListQuery";
import DateTime from "../../components/_utils/date-time/DateTime";
import {useEffect, useState} from "react";

type KillViewProps = {
    page: Page<Kill> | undefined;

    /**
     * KillApi [ListQuery].
     * To filter.
     * @author Kacper Faber
     */
    query: ListQuery;

    /**
     * Update search query.
     * @author Kacper Faber
     * @param query
     */
    setQuery: (query: ListQuery) => void;

    loading: boolean;
}

/**
 * initial sorting status.
 * @author Kacper Faber
 */
const initSort: DataTableSortStatus = {
    columnAccessor: 'dateTime',
    direction: 'desc'
};

/**
 * List of paginated kills.
 * @param props
 * @constructor
 */
function KillView(props: KillViewProps) {
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>(initSort);

    const setProcessName = (processName: string) => {
        props.setQuery({...props.query, processName});
    }

    const clearProcessName = () => {
        props.setQuery({...props.query, processName: undefined});
    }

    const setComputerUserName = (userName: string) => {
        props.setQuery({...props.query, computerUserName: userName});
    }

    const clearComputerUserName = () => {
        props.setQuery({...props.query, computerUserName: undefined});
    }

    const columns: DataTableColumn[] = [
        {accessor: 'id', title: '#', sortable: true},
        {accessor: 'computer.title', title: 'Nazwa komputera', sortable: true, sortKey: 'computer__title'},
        {
            accessor: 'computer.userName',
            title: 'Nazwa użytkownika',
            sortable: true,
            filter: _ => (
                <Stack>
                    <TextInput
                        placeholder="Nazwa użytkownika"
                        value={props.query.computerUserName}
                        onChange={(e) => setComputerUserName(e.target.value)}
                    />

                    <Button
                        color="red"
                        size="xs"
                        onClick={clearComputerUserName}
                        children="Wyczyść"
                    />
                </Stack>
            )
        },
        {accessor: 'computer.domainName', title: 'Domena', sortable: true, sortKey: 'computer__domain_name'},
        {accessor: 'title', title: 'Program', sortable: true, sortKey: 'title'},
        {
            accessor: 'dateTime',
            title: 'Czas zakończenia',
            sortable: true,
            render: r => <DateTime dateTime={(r as any).dateTime} />,
            sortKey: 'date_time'
        },
        {accessor: 'processId', title: 'ID Procesu (PID)'},
        {
            accessor: 'processName',
            title: 'Nazwa procesu',
            filter: _ => (
                <Stack>
                    <TextInput
                        placeholder="Nazwa procesu"
                        value={props.query.processName}
                        onChange={(e) => setProcessName(e.target.value)}
                    />

                    <Button
                        color="red"
                        size="xs"
                        onClick={clearProcessName}
                        children="Wyczyść"
                    />
                </Stack>
            ),
            filtering: (props.query.processName !== undefined) && (props.query.processName !== ''),
            sortable: true,
            sortKey: 'process_name'
        },
    ];

    const reloadSort = () => {
        props.setQuery({...props.query, sortBy: sortStatus.sortKey, sortDir: sortStatus.direction});
    }

    useEffect(reloadSort, [sortStatus]);

    return (
        <>
            <DataTable
                backgroundColor="transparent"
                styles={{header: {background: 'var(--mantine-color-dark-7)'}}}
                withColumnBorders
                highlightOnHover
                columns={columns}
                records={props.page?.results}
                fetching={props.loading}
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                height="50vh"
                noRecordsText="Ale pusto..."
                mb="md"
            />

            <Group justify="center">
                <Pagination
                    total={props.page?.totalPages ?? 1}
                    onChange={(e) => props.setQuery({...props.query, page: e})}
                />
            </Group>
        </>
    );
}

export default KillView;

// TODO: Implement KillView.tsx